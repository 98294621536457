<template>

    <div>

        <section class="hero">
            <div class="hero-inner">
                <div class="hero-inner-text">
                    <div>
                        <h4 style="font-size: xxx-large; font-weight: bold;" class="text-danger">We are migrating to Sabasi v2!</h4>
                        <p>Sabasi is moving to a new platform on 30<sup>th</sup> September 2024. Please check your email for instructions or contact support if you have any questions. Thank you for using Sabasi!</p>

                        <div class="hero-buttons">
                            <a href="#footer" target="_blank" class="hero-btn hero-btn-primary">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- Hero -->
        <section class="hero">
            <div class="hero-inner">
                <img class="hero-bg-img" src="@/assets/img/halftone-texture-bg.png" alt=""/>
                <div class="hero-inner-text">
                    <h1>Data collection & analysis simplified<span>.</span></h1>
                    <p>Create powerful surveys and transform responses into usable data.</p>
                    <div class="hero-buttons">
                        <router-link to="/register">
                            <button class="hero-btn hero-btn-primary">Get Started</button>
                        </router-link>
                        <router-link to="/features">
                            <button class="hero-btn hero-btn-secondary">Explore Features</button>
                        </router-link>
                    </div>                   
                </div>                
            </div>
            <!-- Hero media -->
            <div class="hero-media">
                <picture class="hero-media-img">
                    <source srcset="@/assets/img/sabasi-hero-media.png" />
                    <img src="@/assets/img/sabasi-hero-media.png" alt="Desktop and mobile preview" />
                </picture>
            </div>             
        </section>        

        <!-- How it works -->
        <section class="steps">
            <div class="container-lg">
                <h2 class="section-title">How it works</h2>
                <div class="steps-list">
                    <ul>
                        <li class="steps-list-item">
                            <div class="steps-list-item__icon">
                                <img src="@/assets/img/document.png" alt="Create survey" />
                            </div>
                            <h4>Create a survey</h4>
                            <p>Choose from a variety of question types suited to your survey.</p>
                        </li>
                        <li class="steps-list-item">
                            <div class="steps-list-item__icon">
                                <img src="@/assets/img/user.png" alt="Collect responses" />
                            </div>
                            <h4>Collect responses</h4>
                            <p>Choose how you want to view your responses.</p>
                        </li>    
                        <li class="steps-list-item">
                            <div class="steps-list-item__icon">
                                <img src="@/assets/img/analysing.png" alt="Analyse &amp; react" />
                            </div>
                            <h4>Analyse &amp; react</h4>
                            <p>Get real-time reports with a click of a button.</p>
                        </li>                                             
                    </ul>
                </div>
            </div>
        </section>

        <!-- Built in Africa -->
        <section class="in-africa">
            <div class="container-lg">
                <div class="in-africa-inner">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="in-africa-text">
                                <h2 class="section-title">Built in Africa, for <span class="text-oi-green">everyone</span></h2>
                                <p>Sabasi is a free to use application that is ideal for all types of researchers, including private and public organisations, communities and individuals, such as students and independent researchers.</p>
                                <p class="mb-4">It was developed by Open Institute, a non-profit organisation based in Nairobi, Kenya that collaborates with Governments, Civil Society Organisations, Citizen Groups and Private Sector companies to find innovative ways to achieve Sustainable Development.</p>
                                <a href="https://www.youtube.com/watch?v=U_wIwHbS3x0" target="_blank" rel="nofollow noreferrer">
                                    <button class="hero-btn hero-btn-secondary">
                                        Watch the Launch Video
                                        <font-awesome-icon icon="external-link-alt" />
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="in-africa-img">
                                <!--<img src="@/assets/img/circle-of-people.png" alt="Circle of people" />-->
                                <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="560" height="315" type="text/html" src="https://www.youtube.com/embed/CcEa5eFtGIY?autoplay=0&fs=0&iv_load_policy=3&showinfo=1&rel=0&cc_load_policy=1&start=0&end=0&origin=http://youtubeembedcode.com"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Features Preview -->
        <section class="features">
            <div class="container-lg">
                <h2 class="section-title">What you can do</h2>
                <div class="features-inner">

                    <div class="feature">
                        <span class="feature-icon">
                            <img src="@/assets/img/form-builder.png" alt="Form icon" />
                        </span>
                        <div class="feature-info">
                            <h5>Form Builder</h5>
                            <p>Build your questionnaire with ease. Choose from a variety of question types to suit your survey needs.</p>
                        </div>
                    </div>

                    <div class="feature">
                        <span class="feature-icon">
                            <img src="@/assets/img/language.png" alt="Language icon" />
                        </span>
                        <div class="feature-info">
                            <h5>Multilingual Support</h5>
                            <p>Create and preview your survey in multiple languages. Currently, the application supports English, French and Swahili.</p>
                        </div>
                    </div>        

                    <div class="feature">
                        <span class="feature-icon">
                            <img src="@/assets/img/map-marker.png" alt="Map marker icon" />
                        </span>
                        <div class="feature-info">
                            <h5>Geolocation</h5>
                            <p>Gather geolocation data about your respondents and display results in a heatmap.</p>
                        </div>
                    </div>        

                    <div class="feature">
                        <span class="feature-icon">
                            <img src="@/assets/img/branch.png" alt="Branch icon" />
                        </span>
                        <div class="feature-info">
                            <h5>Conditional Logic</h5>
                            <p>Control the flow of your questionnaire by enabling conditional logic.</p>
                        </div>
                    </div>          

                    <div class="feature">
                        <span class="feature-icon">
                            <img src="@/assets/img/no-wifi.png" alt="Wifi icon" />
                        </span>
                        <div class="feature-info">
                            <h5>Offline Mode (Android)</h5>
                            <p>Transfer reponses between devices via Bluetooth when Internet connectivity is limited or when there is no Internet connection.</p>
                        </div>
                    </div>      

                    <div class="feature">
                        <span class="feature-icon">
                            <img src="@/assets/img/business-report.png" alt="Report icon" />
                        </span>
                        <div class="feature-info">
                            <h5>Real-Time Reports</h5>
                            <p>View the results of your survey in real time. Reports are easy to interpret using bar charts and pie charts.</p>
                        </div>
                    </div>                                                                  

                </div><!-- End features-inner -->

                <router-link class="features-link" to="/features">Discover the features</router-link>
                
            </div>
        </section>

        <!-- App Preview -->
        <section class="app-preview">
            <div class="container-lg">
                <div class="app-preview-inner">
                    <div class="row">
                        <div class="col-lg-5 order-2 order-md-1">
                            <img class="app-screen-cropped" src="@/assets/img/bluetooth-sharing-mobile.png" alt="Bluetooth sharing on mobile app" />
                        </div>
                        <div class="col-lg-7 order-1 order-md-2">
                            <div class="app-preview-info">
                                <h2 class="section-title">Get your answers online and offline</h2>
                                <p>We designed the mobile application as a lightweight version of Sabasi to be used in resource constrained environments where Internet connectivity is limited.</p>
                                <a href="https://play.google.com/store/apps/details?id=com.sabasi.mobile" target="_blank" title="Download Sabasi on Google Play">
                                    <img class="appstore-badge" src="@/assets/img/google-play-badge.png" alt="Google Play badge" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Partners -->
        <section class="partners bg-white">
            <div class="container-lg">
                <div class="partners-inner">
                    <h2 class="section-title">Our partners</h2>
                    <div class="partners-grid">
                        <div class="partners-grid-item">
                            <img src="@/assets/img/landing/amref-logo.png" height="150" width="225" alt="Amref logo" />
                        </div>
                        <div class="partners-grid-item">
                            <img src="@/assets/img/landing/ltfhc-logo.png" height="150" width="225" alt="Lake Tanganyika Floating Health logo" />
                        </div>   
                        <div class="partners-grid-item">
                            <img src="@/assets/img/landing/kilifi-county-logo.png" height="150" width="225" alt="Amref logo" />
                        </div>                                             
                    </div>
                </div>
            </div>
        </section>

        <!-- CTA -->
        <section class="cta">
            <div class="container-lg">
                <div class="cta-inner">
                    <h2 class="section-title">Get started now for free.</h2>
                    <p>Want to create awesome surveys? Sign up for free on the web or download the mobile app today.</p>
                    <div class="cta-buttons">
                        <router-link to="/register">
                            <button class="hero-btn hero-btn-secondary">Sign Up</button>
                        </router-link>
                        <a href="https://play.google.com/store/apps/details?id=com.sabasi.mobile" target="_blank" title="Download Sabasi on Google Play">
                            <img class="appstore-badge" src="@/assets/img/google-play-badge.png" alt="Google Play badge" />
                        </a>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>

<script>
export default {
    name: 'Home',
    methods: {
        auth() {
            return localStorage.getItem(process.env.VUE_APP_NAME + "_token") != null;
        }
    }
}
</script>

<style scoped>
</style>
